/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, SeparateLine, SeparateLineWrap, Subtitle, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"klub"}>
        <Column className="--menu pb--0 pt--0" style={{"marginTop":0,"marginBottom":0,"backgroundColor":"rgba(199,18,18,1)"}} menu={true} border={null}>
        </Column>


        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column style={{"paddingTop":8,"paddingBottom":22.26666259765625}} name={"3djjddxu12h"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr5 btn-box--cbtn2 btn-box--pbtn2 btn-box--shape5 btn-box--cColor1 ff--3" href={"#klubclen"} content={"Informace a členství"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":11,"paddingBottom":19.51666259765625,"backgroundColor":"rgba(165,190,203,1)"}} name={"v2o6uksmnqp"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3 fs--48" content={"Chystané akce klubu Orca<font color=\"rgba(208,2,27,1)\"><br></font>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0}} name={"borenka"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0}} name={"orcabazar"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0}} name={"xaimi32siy9"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0}} name={"bazenvanoce"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":"","paddingTop":0}}>
              
              <Subtitle className="subtitle-box subtitle-box--center ff--3" style={{"marginTop":16.25,"paddingTop":0}} content={"<font color=\"#ca1313\">Vánoční bazén<br></font>"}>
              </Subtitle>

              <Image className="--shape4" style={{"maxWidth":310,"marginTop":23}} src={"https://cdn.swbpg.com/t/13078/b74de96a256a4cb2848f39a6e3a08b66_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/b74de96a256a4cb2848f39a6e3a08b66_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/b74de96a256a4cb2848f39a6e3a08b66_s=660x_.jpg 660w"} lightbox={true}>
              </Image>

              <Text className="text-box text-box--center ff--3 pl--0" style={{"maxWidth":982,"marginTop":16.958343505859375}} content={"<span style=\"color: rgb(206, 42, 42); font-weight: bold;\">Termín: </span><span style=\"color: rgb(0, 0, 0);\">nedělě 15. 12. 2024 od 13 do 14 hodin</span><br><span style=\"color: rgb(0, 0, 0);\"><br>Těšíme se na vás při dovádění na hladině i potápění ke stromečku. Děti si mohou zasoutěžit o malé odměny a dospělí potrénovat při sváteční atmosféře.<br></span><br>Sraz ve 12:30 u bazénu Výstaviště<br>Vstupné 180 / osobu<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0}} name={"xaimi32siy9"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0}} name={"olovo"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":"","paddingTop":0}}>
              
              <Subtitle className="subtitle-box subtitle-box--center ff--3" style={{"marginTop":16.25,"paddingTop":0}} content={"<font color=\"#ca1313\">Lití olova<br></font>"}>
              </Subtitle>

              <Image className="--shape4" style={{"maxWidth":310,"marginTop":23}} src={"https://cdn.swbpg.com/t/13078/ebbeb31cfad54bf09dc59db8041b1ae4_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/ebbeb31cfad54bf09dc59db8041b1ae4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ebbeb31cfad54bf09dc59db8041b1ae4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ebbeb31cfad54bf09dc59db8041b1ae4_s=860x_.jpg 860w"} lightbox={true}>
              </Image>

              <Text className="text-box text-box--center ff--3 pl--0" style={{"maxWidth":982,"marginTop":16.958343505859375}} content={"<span style=\"color: rgb(206, 42, 42); font-weight: bold;\">Termín: </span><span style=\"color: rgb(0, 0, 0);\">úterý&nbsp;17. 12. 2024 od 15 do 18 hodin</span><br><span style=\"color: rgb(0, 0, 0);\"><br>Odlévání zátěže, malé občerstvení a povídání.<br></span><br>Pokud vám přebývá, vezměte cukroví na ochutnání :)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0}} name={"xaimi32siy9"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingTop":29,"paddingBottom":29.449996948242188,"backgroundColor":"rgba(165,190,203,1)"}} name={"klubclen"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<font color=\"#ca1313\">ORCA\n- Klub potápěčů, p.s.</font>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":29,"marginBottom":0,"paddingBottom":29.3515625}} name={"hev0p8pqo76"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--bottom" style={{"maxWidth":582,"marginTop":0,"paddingTop":0}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center ff--3" content={"<font color=\"rgba(0,0,0,1)\">ORCA DIVING, s.r.o.\n<br>Ledařská 433/9\n<br>147 00, Praha 4\n<br><br>IČO: 75061694\n<br>Číslo účtu: 200687951/0300\n\n<br><br>Telefon: 603 310 054\n<br>E-mail: orca@orcadiving.cz\n<br><br>Předseda klubu: Jaroslava Císařová</font>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":425}}>
              
              <Image style={{"maxWidth":247}} src={"https://cdn.swbpg.com/o/13078/13fbea9c3ec149f59e5ce0afe6573009.jpg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":21,"paddingBottom":3.91668701171875}} name={"3cmf1c8lc9m"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--26" content={"<u>Výhody členství v klubu Orca<br></u>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":37,"paddingBottom":55.1583251953125}} name={"bignrzkeivk"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":668}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(165,190,203,1)"}}>
              
              <Text className="text-box" style={{"maxWidth":654}} content={"50% sleva na vstup do plaveckého bazénu Slávie<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(165,190,203,1)"}}>
              
              <Text className="text-box" style={{"maxWidth":654}} content={"50% sleva na zapůjčení potápěčské výstroje<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(165,190,203,1)"}}>
              
              <Text className="text-box" style={{"maxWidth":654}} content={"50% sleva na plnění lahví (vzduch)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(165,190,203,1)"}}>
              
              <Text className="text-box" style={{"maxWidth":654}} content={"5% sleva na nákup výstroje<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(165,190,203,1)"}}>
              
              <Text className="text-box" style={{"maxWidth":654}} content={"Sleva 1000,- na zvyšování potápěčské kvalifikace (P**/AOWD, P***/Divemaster)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"backgroundColor":"rgba(165,190,203,1)"}}>
              
              <Text className="text-box" style={{"maxWidth":654}} content={"1x ročně prohlídka plicní automatiky zdarma<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":0}} name={"ujryl9v49id"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--3 fs--22" style={{"marginBottom":0,"paddingBottom":0}} content={"<font color=\"#ca1515\">Členský příspěvek je 2.000,-<br></font>"}>
              </Subtitle>

              <Text className="text-box ff--3" style={{"maxWidth":650,"marginTop":15.975006103515623,"paddingTop":0}} content={"<font color=\"rgba(0,0,0,1)\">Platbu můžete provést v hotovosti v ORCA, nebo převodem na účet 200687951/0300 (do zprávy pro příjemce uveďte své celé jméno). Noví zájemci o členství, kteří jsou v základním kurzu potápění, mohou do KLUBU ORCA přistoupit kdykoliv během roku a platba členského příspěvku na daný rok bude ve výši poměrné části od data jejich přihlášení.\n<br>Členské karty jsou k vyzvednutí na prodejně.</font>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}